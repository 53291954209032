export const firebaseConfig = {
  apiKey: "AIzaSyBSAhGflxcpXN_3uDPMvSAUH0t9Yt6sgoY",
  authDomain: "visionbeyond-demo.firebaseapp.com",
  databaseURL: "https://visionbeyond-demo.firebaseio.com",
  projectId: "visionbeyond-demo",
  storageBucket: "visionbeyond-demo.appspot.com",
  messagingSenderId: "628081602733",
  appId: "1:628081602733:web:6eefd199b428c4913ed624"
}

export const vapidKey = `BIC9H-iLVcNF4AyEeo27qQzrNy5m6GcjudTXI5zi2gy-ThzQZQVtIEnml4MS7UbYgw1n6yJhp04IoXC6lwnDyRs`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}